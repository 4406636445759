import React from 'react';
import { Helmet } from 'react-helmet';
import profilePicture from '../assets/images/Nahom Mengistu Birhan.jpg';
import '../assets/components/_home.scss';
import Research from './Research';  // Make sure this path is correct
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Nahom M. Birhan - Machine Learning & AI Software Engineer</title>
        <meta name="description" content="Nahom M. Birhan - Machine Learning and AI Software Engineer specializing in Cybersecurity and Data Analytics." />
      </Helmet>
      <div className="home-container">
        <div className="content-wrapper">
          <div className="intro-section">
            <div className="image-container">
              <img src={profilePicture} alt="Nahom M. Birhan" className="profile-image" />
            </div>
            <div className="text-container">
              <h1>Nahom M. Birhan</h1>
              <h2>Machine Learning & AI Software Engineer</h2>
              <p>
                Machine Learning and AI Software Engineer with over 5 years of professional experience specializing in Applied Machine Learning for Cybersecurity and Data Analytics. Experienced in developing ML solutions for edge devices, IoT, and software applications, with a focus on implementing deep learning models for security purposes.
              </p>
              <p>
                Proficient in anomaly detection, image processing, and network security using TensorFlow, PyTorch, and AWS. Committed to ethical AI development and innovative applications of machine learning in security and data analysis.
              </p>
              <div className="button-container">
                <button className="btn primary"> <Link to="/projects">Projects</Link></button>
                <button className="btn primary"> <Link to="/Research">Research</Link></button>
                <button className="btn secondary"> <a href="mailto:nahommengistu13@gmail.com" aria-label="Email"> Email </a> </button>
              </div>
            </div>
          </div>
          <div className="research-section">
            <Research isHomePage={true} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;