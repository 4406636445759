
import React, { useState, useEffect } from 'react';
import '../assets/components/_cv.scss';

const CV = () => {
  const [cvLink, setCvLink] = useState('');
  const [linkError, setLinkError] = useState(false);

  useEffect(() => {
    const fileId = process.env.REACT_APP_CV_FILE_ID;
    if (fileId) {
      setCvLink(`https://drive.google.com/file/d/${fileId}/view`);
    } else {
      setLinkError(true);
    }
  }, []);

  const handleDownload = () => {
    if (cvLink) {
      window.open(cvLink, '_blank');
    }
  };

  if (linkError) {
    return <div>Error: Unable to load CV link.</div>;
  }

  return (
    <div className="cv-container">
      <div className="content-wrapper">
        <div className="cv-header">
          <button onClick={handleDownload} className="btn btn-primary cv-download-btn">
            View/Download CV
          </button>
        </div>

        <div className="cv-section">
          <h2>SKILLS AND CERTIFICATES</h2>
          <div className="skills-list">
              <span className="skill-item">NumPy</span>
              <span className="skill-item">Flask</span>
              <span className="skill-item">Pandas</span>
              <span className="skill-item">SciPy</span>
              <span className="skill-item">Matplotlib</span>
              <span className="skill-item">PyTorch</span>
              <span className="skill-item">TensorFlow</span>
              <span className="skill-item">Scikit-Learn</span>
              <span className="skill-item">ReactJS</span>
              <span className="skill-item">JavaScript</span>
              <span className="skill-item">Python</span>
              <span className="skill-item">Java</span>
              <span className="skill-item">NodeJS</span>
              <span className="skill-item">SQL</span>
              <span className="skill-item">PostgreSQL</span>
              <span className="skill-item">Amazon DynamoDB</span>
              <span className="skill-item">MongoDB</span>
              <span className="skill-item">Alembic</span>
              <span className="skill-item">SQLAlchemy</span>
              <span className="skill-item">Wireshark</span>
              <span className="skill-item">TCP Dump</span>
              <span className="skill-item">Nmap</span>
              <span className="skill-item">Security Onion</span>
              <span className="skill-item">KaliLinux</span>
              <span className="skill-item">Metasploit</span>
              <span className="skill-item">GNS3</span>
           
          </div>
        </div>

        <div className="cv-section">
          <h2>EXPERIENCE</h2>
          <div className="experience-item">
              <h3>Cardinal Peak , Lafayette, CO, USA</h3>
              <h4>Embedded Software and ML Engineer June 2022 — June 2024</h4>
              <ul>
                <li>
                  <strong>CS Investigations Camera Solutions Platform (Team):</strong>
                  <p>A comprehensive camera solutions platform integrating web technologies with custom-designed embedded systems, emphasizing secure communication and robust user management.</p>
                  <ul>
                    <li>Developed full-stack application using Flask (backend), ReactJS (frontend), and PostgreSQL (database) with ORM and migration tools.</li>
                    <li>Implemented robust security features including user authentication, authorization, and mutual TLS for secure camera-server communications.</li>
                    <li>Created camera management system with registration, deletion, and assignment functionalities, integrating OpenCV for image processing.</li>
                    <li>Utilized Docker for containerization and authored deployment scripts for both remote and local server deployments.</li>
                  </ul>
                  <p><em>Tools & technologies:</em> Docker, Docker Compose, Mutual TLS, X.509, self-signed certificates and CAs, Deployment Automation (Python scripting, SSH), Bit-bucket, OpenCV, Jira, PostgreSQL, Alembic, SQLAlchemy, Visual Studio Code, React, Flask</p>
                </li>
                <li>
                  <strong>Dumpster Fullness Detection:</strong>
                  <p>A computer vision solution to monitor and report dumpster fullness levels using AWS services and machine learning.</p>
                  <ul>
                    <li>Developed end-to-end ML pipeline: image collection (AWS DeepLens), storage (S3), model training (SageMaker), and deployment (containerized for edge inference on DeepLens).</li>
                    <li>Implemented secure AWS infrastructure: configured IAM roles, S3 bucket policies, and Lambda functions for data processing and access control.</li>
                    <li>Ensured scalability and consistency across environments through containerization and AWS service integration.</li>
                  </ul>
                  <p><em>Tools & technologies:</em> AWS (DeepLens, S3, SageMaker, IAM, Lambda, AWS Greengrass); Machine Learning (CNN, TensorFlow, Image Classification), Visual Studio Code, Bitbucket, Jira, Docker, AWS Lambda.</p>
                </li>
                <li>
                  <strong>Live Digit Recognition EdgeML (POC) Project:</strong>
                  <p>A real-time hand digit recognition system on an edge device, showcasing the capabilities of TinyML in practical applications.</p>
                  <ul>
                    <li>Developed and optimized a CNN model for live hand digit recognition using TensorFlow and MNIST dataset.</li>
                    <li>Deployed the model on Arduino Nano BLE 33 using TensorFlow Lite, demonstrating edge ML capabilities.</li>
                    <li>Integrated camera input and optimized model execution for resource-constrained edge devices.</li>
                  </ul>
                  <p><em>Tools & Technologies:</em> Arduino Nano BLE 33, TensorFlow Lite, TensorFlow, Visual Studio Code, Bitbucket, Jira, Google Colab</p>
                </li>
                <li>
                  <strong>Chatbot:</strong>
                  <p>A web-based chatbot system, exploring both traditional and advanced NLP techniques while providing a user-friendly interface.</p>
                  <ul>
                    <li>Developed a traditional chatbot using data structures and explored integration of Google's negative news vocabulary embeddings.</li>
                    <li>Created a full-stack application with ReactJS frontend and Flask backend API for chatbot logic and responses.</li>
                    <li>Implemented a seamless chat experience by integrating frontend and backend components.</li>
                  </ul>
                  <p><em>Tools & Technologies:</em> Flask, ReactJS, Visual Studio Code, Bitbucket, Jira</p>
                </li>
                <li>
                  <strong>IoT-Based Environmental Monitoring System:</strong>
                  <p>An end-to-end IoT solution for real-time environmental data collection, transmission, and visualization.</p>
                  <ul>
                    <li>Designed and implemented comprehensive system architecture integrating Arduino-based sensor nodes, Raspberry Pi gateway, and cloud services.</li>
                    <li>Developed firmware for environmental data collection and configured BLE and MQTT protocols for efficient data transmission.</li>
                    <li>Created full-stack web application with Node.js backend, MongoDB for data storage, and JavaScript frontend for real-time visualization.</li>
                    <li>Optimized data transmission protocols for reliable communication across varying network conditions.</li>
                  </ul>
                  <p><em>Tools & Technologies:</em> Arduino, Raspberry Pi, Visual Studio Code, Node.js, MongoDB, Github, Jira</p>
                </li>
                <li>
                  <strong>TinyML Tool Assessment and Prototyping:</strong>
                  <p>Evaluated TinyML tools for IoT applications</p>
                  <ul>
                    <li>Assessed tools like Edge Impulse for IoT and edge ML applications</li>
                    <li>Developed proof-of-concept prototypes for real-world scenarios</li>
                  </ul>
                </li>
                <li>
                  <strong>Rain Detection Model Using Acoustic Data:</strong>
                  <p>TinyML model for environmental monitoring</p>
                  <ul>
                    <li>Collected diverse acoustic data from multiple sources and countries</li>
                    <li>Implemented TinyML model on Arduino Nano 33 BLE Sense to detect rain</li>
                    <li>Demonstrated accurate distinction between raining and non-raining conditions</li>
                  </ul>
                </li>
                <li>
                  <strong>Vibration Detection Model:</strong>
                  <p>TinyML model for industrial vibration monitoring</p>
                  <ul>
                    <li>Developed model using built-in sensors for edge devices</li>
                    <li>Explored various data collection and signal processing techniques</li>
                    <li>Implemented real-time, low-power vibration monitoring for industrial applications</li>
                  </ul>
                  <p><em>Tools & Technologies:</em> Arduino, Raspberry Pi, TensorFlow/TensorFlow Lite, Github, Edge Impulse, Signal Processing, Arduino IDE</p>
                </li>
              </ul>
            </div>

            <div className="experience-item">
              <h3>Carnegie Mellon University (CMU), Kigali, Rwanda</h3>
              <h4>Teaching Assistant Sep 2021 — May 2022</h4>
              <p>Courses: Applied Machine Learning: Fall 2021 and Data Inference and AI System Design: Spring 2022</p>
              <ul>
                <li>Delivered recitations, developed assignments, and provided constructive feedback through grading</li>
                <li>Held office hours and assisted with hands-on exercises, offering individualized support on complex topics</li>
              </ul>
            </div>

            <div className="experience-item">
              <h3>Carnegie Mellon University (CMU), Kigali, Rwanda</h3>
              <h4>Graduate Student Intern June 2021 — Sep 2021</h4>
              <ul>
                <li>
                  <strong>Network Anomaly Detection Research:</strong> Developed and evaluated an ML-based algorithm for detecting network anomalies
                  <ul>
                    <li>Implemented algorithm using Savitzky-Golay filter and autoencoder LSTM model</li>
                    <li>Tested on various datasets, analyzed performance, and authored scientific reports</li>
                  </ul>
                  <p><em>Tools & Technologies:</em> GitHub, Visual Studio Code, Jupiter Notebook, TensorFlow, Latex</p>
                </li>
              </ul>
            </div>

            <div className="experience-item">
              <h3>Symbol Technologies PLC, Addis Ababa, Ethiopia</h3>
              <h4>Network and System Engineer Nov 2019 — August 2020</h4>
              <ul>
                <li>
                  <strong>Virtual Internet Service Project:</strong> Designed and deployed a complete VISP solution
                  <ul>
                    <li>Implemented custom server/router infrastructure and managed services with UNMS/UCRM</li>
                    <li>Distributed internet via OLT/GPON devices, handled bandwidth allocation and monitoring</li>
                    <li>Achieved 100% project completion</li>
                  </ul>
                </li>
                <li>
                  <strong>FreePBX Call Center Management:</strong> Administration and support of a FreePBX-based call center system.
                  <ul>
                    <li>Performed routine maintenance, troubleshooting, and issue resolution</li>
                    <li>Implemented customizations and managed user accounts</li>
                    <li>Configured IVR menus to enhance call flow efficiency and improve customer experience</li>
                  </ul>
                </li>
                <li>
                  <strong>Bulk Short Message Service Project:</strong> Development of a system for efficient mass SMS delivery to virtual internet service customers.
                  <ul>
                    <li>Initiated and progressed the project to 70% completion</li>
                    <li>Designed solution for low-cost, time-efficient mass SMS delivery</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="experience-item">
              <h3>Huawei, Addis Ababa, Ethiopia</h3>
              <h4>Network Engineer Intern March 2018 — August 2018</h4>
              <ul>
                <li>
                  <strong>Campus Network Design and Implementation:</strong> Designed and configured a campus-wide network solution
                  <ul>
                    <li>Developed network topology, implemented VLAN segmentation, and configured routing protocols</li>
                    <li>Conducted firmware upgrades and assisted in documentation and SOP creation</li>
                  </ul>
                </li>
                <li>
                  <strong>Cellular Network Performance Analysis:</strong> Evaluated Ethio-telecom's cellular network using Actix software
                  <ul>
                    <li>Analyzed network issues, identified areas for improvement, and contributed to solution strategies</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>


    

        <div className="cv-section">
        <h2>EXPERIENCE</h2>
        <div className="research-item">
              <strong>Impostor Detection in IoT Edge Sensor Networks:</strong>
              <p>Developed lightweight LSTM model for edge devices, optimized using TensorFlow Lite quantization</p>
              <p><em>Technologies:</em> Raspberry PI, Arduino nano 33 BLE, TensorFlow/Lite, Python, C/C++</p>
            </div>
            <div className="research-item">
              <strong>Email Phishing Detection in Africa:</strong>
              <p>Analyzed African-specific phishing patterns, implemented and compared five ML models, mitigated overfitting</p>
              <p><em>Technologies:</em> Scikit-learn, TensorFlow, Numpy</p>
            </div>
            <div className="research-item">
              <strong>Blockchain-Based Healthcare Data Security:</strong>
              <p>Proposed system integrating blockchain and IPFS for secure medical records, compared IPFS and Amazon S3 access times.</p>
              <p><em>Technologies:</em> IPFS, AWS (IAM, S3), Blockchain concepts</p>
            </div>
            <div className="research-item">
              <strong>Prime and Composite Classification:</strong>
              <p>Created dataset of 2 million numbers, developed models using sequence models and 1D-CNN, analyzed performance</p>
              <p><em>Technologies:</em> Python, TensorFlow, NumPy, Google Colab</p>
            </div>
            <div className="research-item">
              <strong>Smart Irrigation System (BSc. research):</strong>
              <p>Designed automated system with XBee sensor communication, integrated motors, pumps, and sensors, developed control algorithms</p>
              <p><em>Technologies:</em> Proteus, XBee, Arduino, C/C++, Various sensors</p>
            </div>
          </div>
           




        </div>

        <div className="cv-section">
          <h2>FUN PROJECTS</h2>
          <ol>
              <li className="project-item">
                <strong>Text Summarizer:</strong> Abstractive summarization using custom-built Transformer architecture
                <ul>
                  <li>Implemented transformer decoder from scratch and optimized for summarization tasks</li>
                </ul>
                <p><em>Tools:</em> Python, TensorFlow, Transformer Architecture</p>
              </li>
              <li className="project-item">
                <strong>Conversational AI:</strong> Dialogue generation system using Reformer (efficient Transformer) model
                <ul>
                  <li>Developed and optimized model for conversational AI tasks</li>
                </ul>
                <p><em>Tools:</em> Python, TensorFlow, Reformer Architecture</p>
              </li>
            </ol>
       



        </div>

        <div className="cv-section">
          <h2>EDUCATION</h2>
          <div className="education-item">
              <strong>M.Sc. Electrical & Computer Engineering, Carnegie Mellon University (CMU)</strong> May 2022
              <p>Focus: Machine Learning (ML) and Cyber Security</p>
            </div>
            <div className="education-item">
              <strong>B.Sc. Electrical & Computer Engineering, Addis Ababa University (AAU)</strong> July 2019
              <p>Focus: Electronics and Communication Engineering</p>
            </div>
          
        </div>

        <div className="cv-section">
          <h2>ACHIEVEMENTS & VOLUNTEER</h2>
          <ul>
              <li className="achievement-item">MasterCard Foundation Scholarship (M.Sc.)</li>
              <li className="achievement-item">Very great distinction certificate (B.Sc.)</li>
              <li className="achievement-item">Served as python programming instructor for selected high school students at Meles Foundation.</li>
              <li className="achievement-item">Served as python programming instructor for selected high school students at Addis Coder.</li>
              <li className="achievement-item">Tutored applied mathematics (I and II) for fresh and second-year engineering students of Addis Ababa University as a volunteer.</li>
              <li className="achievement-item">Founder and Chairperson, "Hagerihin Ewok" Club: Led student organization promoting awareness of national heritage sites. Developed educational programs and organized trips to enhance peer engagement with cultural landmarks.</li>
            </ul>       
      </div>
    </div>
  );
};

export default CV;




