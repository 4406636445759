import React from 'react';
import '../assets/components/_research.scss';

const Research = ({ isHomePage = false }) => {
  return (
    <div className={isHomePage ? '' : 'research-container'}>
    <div className={isHomePage ? '' : 'content-wrapper'}>
        <h1>Research Interests</h1>
        <p className="research-summary">
          My research focuses on the intersection of machine learning, artificial intelligence, and cybersecurity, with a particular emphasis on leveraging ML for cybersecurity applications and exploring the robustness and vulnerabilities of ML models. I'm interested in developing lightweight ML solutions for edge devices and IoT environments, addressing challenges in network security, anomaly detection, and impostor identification. My work extends to investigating email phishing detection, blockchain applications in healthcare data security, and novel ML applications in environmental monitoring. Through this research, I aim to enhance the security and efficiency of AI systems while deepening our understanding of their potential vulnerabilities and limitations in real-world, resource-constrained scenarios.
        </p>
        <h2>Research Areas</h2>
        <div className="skills-list">
          <span className="skill-item">Machine Learning in Cybersecurity</span>
          <span className="skill-item">AI Model Robustness</span>
          <span className="skill-item">ML Vulnerabilities</span>
          <span className="skill-item">Edge Computing and security</span>
          <span className="skill-item">IoT Security</span>
          <span className="skill-item">Network Anomaly/Imposter Detection</span>
          <span className="skill-item">Blockchain and Cryptography in Healthcare</span>
        </div>
        <h2>Research Projects</h2>
        <div className="research-items">
          <div className="research-item">
            <h3>Impostor Detection in IoT Edge Sensor Networks</h3>
            <p>Developed lightweight LSTM model for edge devices, optimized using TensorFlow Lite quantization</p>
            <p><em>Technologies:</em> Raspberry PI, Arduino nano 33 BLE, TensorFlow/Lite, Python, C/C++</p>
          </div>
          <div className="research-item">
            <h3>Email Phishing Detection in Africa</h3>
            <p>Analyzed African-specific phishing patterns, implemented and compared five ML models, mitigated overfitting</p>
            <p><em>Technologies:</em> Scikit-learn, TensorFlow, Numpy</p>
          </div>
          <div className="research-item">
            <h3>Blockchain-Based Healthcare Data Security</h3>
            <p>Proposed system integrating blockchain and IPFS for secure medical records, compared IPFS and Amazon S3 access times.</p>
            <p><em>Technologies:</em> IPFS, AWS (IAM, S3), Blockchain concepts</p>
          </div>
          <div className="research-item">
            <h3>Prime and Composite Classification</h3>
            <p>Created dataset of 2 million numbers, developed models using sequence models and 1D-CNN, analyzed performance</p>
            <p><em>Technologies:</em> Python, TensorFlow, NumPy, Google Colab</p>
          </div>
          <div className="research-item">
            <h3>Smart Irrigation System (BSc. research)</h3>
            <p>Designed automated system with XBee sensor communication, integrated motors, pumps, and sensors, developed control algorithms</p>
            <p><em>Technologies:</em> Proteus, XBee, Arduino, C/C++, Various sensors</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Research;