import React, { useState, useEffect } from 'react';
import axios from 'axios';
import cheerio from 'cheerio';
import ReactMarkdown from 'react-markdown';
import '../assets/components/_blog.scss';

const Blog = () => {
  const [articles, setArticles] = useState([]);
  const mediumUsername = 'nahommengistu13'; 

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@${mediumUsername}`);
        const articlesData = await Promise.all(
          response.data.items.map(async (item) => {
            const content = await fetchArticleContent(item.link);
            return { ...item, fullContent: content };
          })
        );
        setArticles(articlesData);
      } catch (error) {
        console.error('Error fetching Medium articles:', error);
      }
    };

    fetchArticles();
  }, [mediumUsername]);

  const fetchArticleContent = async (url) => {
    try {
      const response = await axios.get(url);
      const $ = cheerio.load(response.data);
      const content = $('article').html();
      return content;
    } catch (error) {
      console.error('Error fetching article content:', error);
      return null;
    }
  };

  return (
    <div className="blog-container">
      <div className="content-wrapper">
        <h1>My Medium Articles on Internet, ML and Cybersecurity</h1>
        <div className="blog-posts">
          {articles.map((article) => (
            <div key={article.guid} className="blog-post">
              <h2>{article.title}</h2>
              <p className="publish-date">{new Date(article.pubDate).toLocaleDateString()}</p>
              <div className="article-content">
                <ReactMarkdown>{article.fullContent}</ReactMarkdown>
              </div>
              <a href={article.link} target="_blank" rel="noopener noreferrer" className="read-more">
                Read on Medium
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;