import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import NavigationBar from './components/Navbar';
import Home from './components/Home';
import Projects from './components/CV';
import Blog from './components/Blog';
import Research from './components/Research';
import Contact from './components/Contact';
import Testimonials from './components/Testimonials';
import Gallery from './components/Gallery';
import Footer from './components/Footer';

import './assets/main.scss';  

// Initialize Google Analytics
ReactGA.initialize("G-4WQ295XRQ7");

// Wrapper component for tracking page views
const PageViewTracker = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return children;
};

const App = () => {
  return (
    <Router>
      <PageViewTracker>
        <div className="App">
          <NavigationBar />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/research" element={<Research />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/testimonials" element={<Testimonials />} />
              <Route path="/gallery" element={<Gallery />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </PageViewTracker>
    </Router>
  );
};

export default App;