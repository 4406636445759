// src/components/Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaGithub, FaTwitter, FaMedium, FaEnvelope } from 'react-icons/fa';
import '../assets/components/_footer.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <Container>
        <Row className="footer-main">
          <Col md={4} className="footer-about">
            <h5>About Me</h5>
            <h6>
              Machine Learning Engineer and Cybersecurity Expert passionate about leveraging AI to enhance digital security.
            </h6>
          </Col>
          <Col md={2} className="footer-links">
            <h5>Quick Links</h5>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/Research">Research</Link></li>
              <li><Link to="/projects">Projects</Link></li>
              <li><Link to="/blog">Blog</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </Col>
          <Col md={2} className="footer-social">
            <h5>Connect/contact</h5>
            <div className="social-icons">
            <a href="mailto:nahommengistu13@gmail.com" aria-label="Email">
                <FaEnvelope />
              </a>
              <a href="https://www.linkedin.com/in/nahombirhan/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                <FaLinkedin />
              </a>
              <a href="https://github.com/NahomMA" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
                <FaGithub />
              </a>
              <a href="https://x.com/NahomMengistu10" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                <FaTwitter />
              </a>
              <a 
              href="https://medium.com/@nahommengistu13" 
              target="_blank" 
              rel="noopener noreferrer"
              className="btn btn-primary"
            >
              <FaMedium /> Follow on Medium
            </a>
            </div>
          </Col>
         
        
         
        </Row>
        <Row className="footer-bottom">
          <Col>
            <h5>&copy; {currentYear} Nahom M. Birhan. All rights reserved.</h5>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;