import React from 'react';
import '../assets/components/_testimonials.scss';

const Testimonials = () => {
  return (
    <div className="testimonials-container">
      <div className="content-wrapper">
        <h1>Testimonials</h1>
        <div className="coming-soon-card">
          <h2>Stay Tuned!</h2>
          <p>Testimonials are coming soon. Check back later for updates.</p>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;