import React from 'react';
import '../assets/components/_gallery.scss';

const Gallery = () => {
  return (
    <div className="gallery-container">
      <div className="content-wrapper">
        <h1>Gallery</h1>
        <div className="coming-soon-card">
          <h2>Visual Showcase Coming Soon!</h2>
          <p>
            I'm working on curating a collection of visuals that represent my work in Machine Learning and Cybersecurity. 
            Check back later for updates.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Gallery;